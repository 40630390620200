@import "variables";

@mixin page-title-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2.75rem; /* identical to box height, or 122% */
  letter-spacing: 0.2px;
  font-weight: $font-weight;
}

@mixin title-2-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 2.50rem; /* identical to box height, or 143% */
  letter-spacing: 0.1px;
  font-weight: $font-weight;
}

@mixin title-3-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1.50rem;
  line-height: 2.0rem; /* identical to box height, or 133% */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin title-4-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5rem; /* identical to box height, or 280% */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin title-5-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5rem; /* identical to box height, or 280% */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin heading-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.88rem;
  line-height: 1.50rem; /* identical to box height, or 133% */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin body-1-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%; /* identical to box height, or 1.38rem */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin body-2-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%; /* identical to box height, or 1.25rem  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin body-3-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%; /* identical to box height, or 1.25rem  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin caption-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.88rem;
  line-height: 1.50rem; /* identical to box height, or 171%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin subcaption-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1rem; /* identical to box height, or 133%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin subcaption-1-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 0.875rem;
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin button-1-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.25rem; /* identical to box height, or 125%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin button-2-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.9rem;
  line-height: 1.25rem; /* identical to box height, or 143%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin button-3-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1rem; /* identical to box height, or 133%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin tooltip-typo($font-weight: $regular-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-weight: $medium-weight;
  font-size: 0.75rem;
  line-height: 142%;
  letter-spacing: -0.256522px;
}

@mixin slide-toggle-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1rem; /* identical to box height, or 133%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin toast-title-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin toast-content-typo($font-weight: $regular-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.88rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  font-weight: $font-weight;
}


//------------- BUTTON TYPO ---------------------------------------------------------------------------
@mixin large-button-typo() {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: $main-font-family-typo;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 120% */
}

@mixin medium-button-typo() {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: $main-font-family-typo;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 150% */
}

@mixin small-button-typo() {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: $main-font-family-typo;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 142.857% */
}

@mixin badge-content-typo() {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.5rem;
  line-height: 0.875rem;
  letter-spacing: 0;
  font-weight: $bold-weight;
}
