@use "@angular/cdk" as cdk;
@include cdk.overlay();

@import './utils/variables';
@import './utils/typographies';
@import './public/global-base-table';
@import './public/global-input';


:root {

  // each item in color map
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }

  h1 {
    @include page-title-typo()
  }

  h2 {
    @include title-2-typo();

    &.section-title {
      margin-bottom: 2rem;
    }
  }

  h3 {
    @include title-3-typo()
  }

  h4 {
    @include title-4-typo()
  }

  h5 {
    @include title-5-typo()
  }

  label {
    @include heading-typo();
    //margin-bottom: 0.5rem;
  }

  .section-container {
    margin-bottom: 2rem;

    .section-content {
      display: flex;
      flex-direction: column;
      background-color: white;
      box-shadow: 0 12px 28px rgba(13, 19, 27, 0.08);
      border-radius: 0.7rem;
      padding: 2rem;
      gap: 1rem;
    }
  }

  font-variant-numeric: tabular-nums;

  .bold {
    font-weight: 700;
  }

  .highlighted {
    background-color: #FFF500;
  }

}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $grey-color-200;
  background-color: white;
  //border-radius: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: white;
  //border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: $grey-color-400;
  //border-radius: 8px;
}

* {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  height: 100vh;
}

//flex-params
.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.highcharts-menu {
  display: flex;
  padding: 0.625rem !important;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.25rem 0.25rem 0.25rem 0;
  border: none !important;
  background: white;

  .highcharts-menu-item {
    @include subcaption-typo();
    color: $grey-color-800 !important;

    &:hover {
      background: $grey-color-100 !important;
      padding: 1rem;
    }
  }
}

.highcharts-tooltip {
  .date {
    @include body-3-typo();
    font-size: .625rem;
    text-transform: uppercase;
    color: $grey-color-750;
  }

  .market-price-date {
    @include body-3-typo($bold-weight);
    font-size: .625rem;
    text-transform: capitalize;
    color: black;
  }

  .poste {
    @include body-3-typo();
    font-size: .75rem;
    line-height: 130%;
    color: $grey-color-800;
    margin: .375rem 0;
  }

  .bullet {
    font-size: 1rem;
    margin-top: .5rem;
  }

  .value {
    @include body-3-typo($medium-weight);
    font-size: .75rem;
    line-height: 130%;

    strong {
      font-size: .875rem;
    }
  }

  .market-price-value {
    @include subcaption-1-typo($regular-weight);
    color: $grey-color-800;

    strong {
      @include subcaption-1-typo($semibold-weight);
    }
  }
}

app-offers-missing {
  a {
    @include body-1-typo($regular-weight);

    color: $info-color-600;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $title-font-family-typo;
    text-decoration-line: underline;
  }
}
