@use './../utils/variables' as var;
@use './../utils/typographies' as typo;


.text-input-container {
  display: flex;
  flex-direction: column;
}

.autocomplete {
  background: white;
  border: 1px solid var.$success-color-500;
  border-top: none;
  box-shadow: 3px 2px 0 1px var.$success-color-100, -3px 2px 0 1px var.$success-color-100;
}

.checkbox-input-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  label {
    @include typo.button-2-typo(var.$regular-weight);
    color: var.$grey-color-800;
    text-align: center;
  }

  input[type="checkbox"] {
    border: 1px solid var.$grey-color-100;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var.$white;
    align-self: center;

    &:hover {
      border-color: var.$primary-color-400;
    }
  }
}
