//----------------------------- COLORS ----------------------------

//Primary
$primary-color: #40a678;
$primary-lighter-color: #c6e4d7;
$primary-darker-color: #2a8b5b;

//Secondary
$accent-color: #d7e25a;
$accent-lighter-color: #f3f6ce;
$accent-darker-color: #c7d63f;

//Warning
$warn-color: #ee7d15;
$warn-lighter-color: #fad8b9;
$warn-darker-color: #e7600c;
//primary-colors
$primary-color-20: #F6FFFB;
$primary-color-50: #E3FFF2;
$primary-color-100: #B0FFDB;
$primary-color-200: #63FFB8;
$primary-color-300: #5EF2AF;
$primary-color-400: #54D89C;
$primary-color-500: #40A678;
$primary-color-600: #2C7353;
$primary-color-700: #225940;
$primary-color-800: #18402E;
$primary-color-900: #0F261C;


//secondary-colors
$secondary-color-50: #FFFBF4;
$secondary-color-100: #EBF66E;
$secondary-color-200: #E1EC64;
$secondary-color-300: #D7E25A;
$secondary-color-400: #889400;
$secondary-color-500: #373C00;
$secondary-color-600: #639AFF;
$secondary-color-700: #003838;

$secondary-color-blue: #639AFF;
$secondary-color-light-blue: #8CB3FA;
$secondary-color-dark-blue: #2C61C1;


//success-colors
$success-color-100: #D1F7E0;
$success-color-300: #5CE6A3;
$success-color-500: #18D865;
$success-color-600: #0F853e;
$success-color-700: #07411E;
$success-color-900: #02160A;

//warning-colors
$warning-color-100: #FCE5D0;
$warning-color-300: #F5B173;
$warning-color-500: #EE7D15;
$warning-color-700: #8F4B0D;
$warning-color-900: #180C02;

//danger-colors
$danger-color-100: #FCD3D0;
$danger-color-300: #F57A73;
$danger-color-500: #EE2215;
$danger-color-700: #77110B;
$danger-color-900: #130202;

//info-colors
$info-color-100: #D3DFF9;
$info-color-300: #7C9EEC;
$info-color-500: #255DDF;
$info-color-600: #1657ED;
$info-color-700: #163886;
$info-color-900: #07132D;

//grey-colors
$grey-color-0: #fff;
$grey-color-50: #F8F9FA;
$grey-color-100: #F1F2F5;
$grey-color-200: #E9EBEF;
$grey-color-300: #DEE0E6;
$grey-color-400: #CED2DA;
$grey-color-500: #ADB2BD;
$grey-color-600: #868B96;
$grey-color-700: #585D66;
$grey-color-750: #3D4149;
$grey-color-800: #1D212A;
$grey-color-900: #111827;
$black: #13161D;
$white: #ffffff;

//tag-colors
$tag-font-color-turquoise: #D3FFF2;
$tag-text-color-turquoise: #00442F;
$tag-font-color-light-blue: #CFF1FF;
$tag-text-color-light-blue: #002D34;
$tag-font-color-cyan: #CFE2FF;
$tag-text-color-cyan: #002A69;
$tag-font-color-violet: #CBC3FF;
$tag-text-color-violet: #090043;
$tag-font-color-pink: #FFD7FE;
$tag-text-color-pink: #430040;
$tag-font-color-red: #FFC7D1;
$tag-text-color-red: #3B000B;
$tag-font-color-orange: #FFDCC8;
$tag-text-color-orange: #3F1700;
$tag-font-color-yellow: #FEFFC8;
$tag-text-color-yellow: #323300;
$tag-font-color-anise: #E1FFA1;
$tag-text-color-anise: #1E2B00;
$tag-font-color-green: #C8FFD4;
$tag-text-color-green: #002F0A;
$tag-font-color-gray: #F1F2F5;
$tag-text-color-grey: #000000;

// data-colors
$data-color-blue-50: #93E7EE;
$data-color-blue-100: #68C2BD;
$data-color-green-400: #68C2BD;
$data-color-green-600: #2C7353;
$data-color-green-900: #0F261C;
$data-color-orange-50: #FFEEB2;
$data-color-orange-100: #FFDFBF;
$data-color-orange-200: #FF8F73;
$data-color-orange-300: #F57A73;
$data-color-grey-200: #E9EBEF;
$data-color-grey-400: #CED2DA;
$data-color-hausse: #E82011;
$data-color-baisse: #14A86D;

$color-biomasse: #E2C8BA;
$color-amethyst: #DCCFDD;
$color-decorative-jade: #CBF0C7;
$color-decorative-emerald: #BEEFE2;
$color-eolienne: #C1F5F8;


$colors: (
  primary50: #E3FFF2,
  primary100: #B0FFDB,
  primary200: #63FFB8,
  primary300: #5EF2AF,
  primary400: #54D89C,
  primary500: #40A678,
  primary600: #2C7353,
  primary700: #225940,
  primary800: #18402E,
  primary900: #0F261C,
  secondary50: #FFFBF4,
  secondary100: #EBF66E,
  secondary200: #E1EC64,
  secondary300: #D7E25A,
  secondary400: #889400,
  secondary500: #373C00,
  secondary600: #639AFF,
  secondary700: #003838,
  secondaryBlue: #639AFF,
  secondaryLightBlue: #8CB3FA,
  secondaryDarkBlue: #2C61C1,
  success100: #D1F7E0,
  success300: #74E8A3,
  success500: #18D865,
  success700: #07411E,
  success900: #02160A,
  warning100: #FCE5D0,
  warning300: #F5B173,
  warning500: #EE7D15,
  warning700: #8F4B0D,
  warning900: #180C02,
  danger100: #FCD3D0,
  danger300: #F57A73,
  danger500: #EE2215,
  danger700: #77110B,
  danger900: #130202,
  info100: #D3DFF9,
  info300: #7C9EEC,
  info500: #255DDF,
  info700: #163886,
  info900: #07132D,
  greyWhite: #fff,
  grey50: #F8F9FA,
  grey100: #F1F2F5,
  grey200: #E9EBEF,
  grey300: #DEE0E6,
  grey400: #CED2DA,
  grey500: #ADB2BD,
  grey600: #868B96,
  grey700: #585D66,
  grey800: #1D212A,
  grey900: #0D111B,
  greyBlack: #13161D,
  negative: #E82011,
  positive: #14A86D,
);

//----------------------------- BACKGROUND ----------------------------
// Blue bg
$blue-background: #F6F9FF;

// Grey bg
$grey-background: #F8F9FA;

//----------------------------- TEXT ----------------------------

$font-family: 'Inter, sans-serif';

//typography
$title-font-family-typo: "Poppins", sans-serif;
$main-font-family-typo: "Inter", sans-serif;
$regular-weight: 400;
$medium-weight: 500;
$semibold-weight: 600;
$bold-weight: 700;

//----------------------- SCREEN SIZES --------------------------
$desktop-large-width: 1440px;
@mixin desktop-large {
  @media (min-width: #{$desktop-large-width}) {
    @content;
  }
}
