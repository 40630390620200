@use './../utils/variables' as var;
@use './../utils/typographies' as typo;

app-base-table {
  color: var.$black;
  border: 1px solid var.$grey-color-200;
  border-radius: .625rem;

  tr {
    background: white;
    white-space: nowrap;

    &:not(.no-data) td {
      border-bottom: 1px solid var.$grey-color-200;
    }
  }

  thead {
    @include typo.body-2-typo(var.$semibold-weight);

    tr th {
      border-bottom: 1px solid var.$grey-color-200;
    }
  }

  tbody {
    @include typo.body-2-typo(var.$regular-weight);

    tr {
      &.checked {
        background-color: var.$primary-color-50 !important;
      }

      &.expanded-row {
        td {
          padding: 1rem 1.75rem !important;

          .table-layout {
            border: none;
            width: 100%;
          }
        }

        tr {
          border-bottom: none;

          td:first-child {
            div {
              display: flex;
              gap: .625rem;
              align-items: center;
            }
          }
        }

        &:not(.expanded) {
          display: none;
        }
      }

      &:not(.disabled, .checked) {
        &:hover {
          background-color: var.$grey-color-50;
          cursor: pointer;
        }

        &:active {
          background-color: var.$primary-color-50;
          cursor: pointer;
        }
      }
    }
  }

  th, td {
    padding: 1rem .75rem;
    vertical-align: middle;
    white-space: nowrap;

    ngx-skeleton-loader * {
      margin-bottom: -5px !important;
    }
  }

  span.state {
    @include typo.body-3-typo(var.$medium-weight);

    padding: .125rem .5rem;
    border-radius: .5rem;
    color: $black;
    white-space: nowrap;

    &.state-inactive {
      background-color: var.$grey-color-300;
    }

    &.state-green-100 {
      background-color: var.$primary-color-100;
    }

    &.state-danger {
      background-color: var.$danger-color-100;
    }

    &.state-warning {
      background-color: var.$warning-color-100;
    }

    &.state-success {
      background-color: var.$success-color-100;
    }

    &.state-eolienne {
      background-color: var.$color-eolienne;
    }

    &.state-biomasse {
      background-color: var.$color-biomasse;
    }

    &.state-orange-200 {
      background-color: var.$data-color-orange-200;
    }

    &.state-orange-100 {
      background-color: var.$data-color-orange-100;
    }

    &.state-jade {
      background-color: var.$color-decorative-jade;
    }

    &.state-emerald {
      background-color: var.$color-decorative-emerald;
    }

    &.state-orange-50 {
      background-color: var.$data-color-orange-50;
    }

    &.state-amethyst {
      background-color: var.$color-amethyst;
    }

    &.state-grey-300 {
      background-color: var.$grey-color-300;
    }
  }
}
